import React, { useEffect } from "react"
import { Form, Layout, Input, Button, message, Select, DatePicker } from 'antd'
import { endpoints } from "../../lib/endpoint"
import { ID_TYPES } from "../../lib/constants"
import axios from "axios"
import {
    useParams
} from "react-router-dom"
import moment from "moment"

const TAIWAN_MARTIN = () => {
    let uid = useParams()?.uid
    const [form] = Form.useForm()
    const [editable, setEditable] = React.useState(false)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}${endpoints.client}?uuid=${uid}&product=NZ&agent=feng`).then(res => {
            if (res.status === 200) {
                form.setFieldsValue({
                    ...res.data,
                    uuid: res.data?.client?.unqiue_hash_id,
                    id_code: res.data?.client?.id_code,
                    passport_expire_date: res.data.passport_expire_date ? moment(res.data.passport_expire_date, 'YYYY-MM-DD') : moment(),
                    birthday: res.data.birthday ? moment(res.data.birthday, 'YYYY-MM-DD') : moment(),
                    when_been_to_nz: res.data.when_been_to_nz ? moment(res.data.when_been_to_nz, 'YYYY-MM-DD') : moment(),
                    id_start: res.data.id_start ? moment(res.data.id_start, 'YYYY-MM-DD') : moment(),
                    been_to_nz: res.data.been_to_nz ? "true" : "false",

                    pay_by_own_card: res.data.pay_by_own_card ? "true" : "false",
                    pay_by_us_if_failed: res.data.pay_by_us_if_failed ? "true" : "false",

                })
                setEditable(res.data?.client?.editable)

            } else {
                message.error('Invalid links')
            }
        })
    }, [uid, form, setEditable])

    const addInfo = (values) => {
        const info = {
            ...values,
            passport_expire_date: values.passport_expire_date?.format('YYYY-MM-DD'),
            birthday: values.birthday?.format('YYYY-MM-DD'),
            id_start: values.id_start?.format('YYYY-MM-DD'),
            been_to_nz: values.been_to_nz === "true",
            when_been_to_nz: values.when_been_to_nz?.format('YYYY-MM-DD'),
            pay_by_own_card: values.pay_by_own_card === "true",
            pay_by_us_if_failed: values.pay_by_us_if_failed === "true",

        }

        axios.post(`${process.env.REACT_APP_BACKEND_URL}${endpoints.client}`, { ...info, unqiue_hash_id: uid, product: "NZ" }).then(res => {
            if (res.status === 200) {
                message.info('Submit success')
            } else {
                message.error('Submit wrong, please retry')
            }

        })
    }

    const layout = {
        labelCol: {
            xs: { span: 12 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 12 },
            sm: { span: 12 },
        },
    }
    return (
        <Layout style={{ margin: "10px" }}>
            <Layout.Header style={{ backgroundColor: "#6495ED", display: "flex", justifyContent: "center" }}>EZIWHV - 註冊您的訊息</Layout.Header>
            <Layout.Content>

                <Form form={form} onFinish={addInfo}
                    {...layout}
                    disabled={editable}
                    style={{ margin: "10px" }}
                >
                    <Form.Item
                        label="ID"
                        name="id_code"
                        hidden
                        disabled    // 只读
                        rules={[{ required: true }]}
                    >
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                        label="ID"
                        name="uuid"
                        disabled    // 只读
                        rules={[{ required: true }]}
                    >
                        <Input disabled />
                    </Form.Item>

                    <Form.Item
                        label="First Name"
                        name="first_name"
                        rules={[{ required: true, message: 'Put your firstname' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Last Name"
                        name="last_name"
                        rules={[{ required: true, message: 'Put your lastname' }]}
                    >
                        <Input />
                    </Form.Item>


                    <Form.Item label="Gender" name="gender" rules={[{ required: true }]} >
                        <Select>
                            <Select.Option value="Male">Male</Select.Option>
                            <Select.Option value="Female">Female</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Passport Number"
                        name="passport"
                        rules={[{ required: true, message: 'Please put your passport number' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Passport expire date"
                        name="passport_expire_date"
                        rules={[{ required: true, message: 'Please input your passport expire date' }]}
                    >
                        <DatePicker format={'YYYY-MM-DD'} />
                    </Form.Item>

                    <Form.Item
                        label="出生日期"
                        name="birthday"
                        rules={[{ required: true, message: 'Please input your birthday' }]}
                    >
                        <DatePicker format={'YYYY-MM-DD'} />
                    </Form.Item>

                    <Form.Item label="Identification Document Type" name="id_type" rules={[{ required: true }]} >
                        <Select>
                            {ID_TYPES.map(item => <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>)}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Date of issue (For the ID you select above):"
                        name="id_start"
                        rules={[{ required: true, message: 'Your ID valid from?' }]}
                    >
                        <DatePicker format={'YYYY-MM-DD'} />
                    </Form.Item>

                    <Form.Item label="Been to New Zealand before:" name="been_to_nz" rules={[{ required: true }]} >
                        <Select>
                            <Select.Option value="true">Yes</Select.Option>
                            <Select.Option value="false">No</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => prevValues.been_to_nz !== currentValues.been_to_nz}
                    >
                        {({ getFieldValue }) =>
                            getFieldValue('been_to_nz') === 'true' ? (
                                <Form.Item name="when_been_to_nz" label="Last time you enter New Zealand" rules={[{ required: true }]}>
                                    <DatePicker format={'YYYY-MM-DD'} />
                                </Form.Item>
                            ) : null
                        }
                    </Form.Item>

                    <Form.Item
                        label="Current address: Street"
                        name="address_street"
                        rules={[{ required: true, message: 'Your street address' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Current address: Suburb"
                        name="address_suburb"
                        rules={[{ required: true, message: 'Your address suburb' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Current address: City"
                        name="address_city"
                        rules={[{ required: true, message: 'You addrress city' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label="Country/Area" name="address_country" rules={[{ required: true }]} >
                        <Input />
                    </Form.Item>

                    {/*<Form.Item label="Pay visa fee by your own card:" name="pay_by_own_card" rules={[{ required: true }]} >*/}
                    {/*    <Select>*/}
                    {/*        <Select.Option value="true">Yes</Select.Option>*/}
                    {/*        <Select.Option value="false">No</Select.Option>*/}
                    {/*    </Select>*/}
                    {/*</Form.Item>*/}

                    {/*<Form.Item*/}
                    {/*    noStyle*/}
                    {/*    shouldUpdate={(prevValues, currentValues) => prevValues.pay_by_own_card !== currentValues.pay_by_own_card}*/}
                    {/*>*/}
                    {/*    {({ getFieldValue }) =>*/}
                    {/*        getFieldValue('pay_by_own_card') === 'true' ? (*/}

                    {/*            <Form.Item label="Payment failed for 4 times, pay by our card:" name="pay_by_us_if_failed" rules={[{ required: true }]} >*/}
                    {/*                <Select>*/}
                    {/*                    <Select.Option value="true">Yes</Select.Option>*/}
                    {/*                    <Select.Option value="false">No</Select.Option>*/}
                    {/*                </Select>*/}
                    {/*            </Form.Item>*/}
                    {/*        ) : null*/}
                    {/*    }*/}
                    {/*</Form.Item>*/}

                    {/*<Form.Item*/}
                    {/*    noStyle*/}
                    {/*    shouldUpdate={(prevValues, currentValues) => prevValues.pay_by_own_card !== currentValues.pay_by_own_card}*/}
                    {/*>*/}
                    {/*    {({ getFieldValue }) =>*/}
                    {/*        getFieldValue('pay_by_own_card') === 'true' ? (*/}

                    {/*            <Form.Item label="Card Type" name="card_type" rules={[{ required: true }]} >*/}
                    {/*                <Select>*/}
                    {/*                    <Select.Option value="MASTERCARD">MasterCard</Select.Option>*/}
                    {/*                    <Select.Option value="VISA">Visa</Select.Option>*/}
                    {/*                </Select>*/}
                    {/*            </Form.Item>*/}
                    {/*        ) : null*/}
                    {/*    }*/}
                    {/*</Form.Item>*/}

                    {/*<Form.Item*/}
                    {/*    noStyle*/}
                    {/*    shouldUpdate={(prevValues, currentValues) => prevValues.pay_by_own_card !== currentValues.pay_by_own_card}*/}
                    {/*>*/}
                    {/*    {({ getFieldValue }) =>*/}
                    {/*        getFieldValue('pay_by_own_card') === 'true' ? (*/}
                    {/*            <Form.Item label="Card Number" name="card_number" rules={[{ required: true }]} >*/}
                    {/*                <Input />*/}
                    {/*            </Form.Item>*/}
                    {/*        ) : null*/}
                    {/*    }*/}
                    {/*</Form.Item>*/}

                    {/*<Form.Item*/}
                    {/*    noStyle*/}
                    {/*    shouldUpdate={(prevValues, currentValues) => prevValues.pay_by_own_card !== currentValues.pay_by_own_card}*/}
                    {/*>*/}
                    {/*    {({ getFieldValue }) =>*/}
                    {/*        getFieldValue('pay_by_own_card') === 'true' ? (*/}
                    {/*            <Form.Item label="Card expire month" name="card_expire_month" rules={[{ required: true }]} >*/}
                    {/*                <Select>*/}
                    {/*                    <Select.Option value="1">1</Select.Option>*/}
                    {/*                    <Select.Option value="2">2</Select.Option>*/}
                    {/*                    <Select.Option value="3">3</Select.Option>*/}
                    {/*                    <Select.Option value="4">4</Select.Option>*/}
                    {/*                    <Select.Option value="5">5</Select.Option>*/}
                    {/*                    <Select.Option value="6">6</Select.Option>*/}
                    {/*                    <Select.Option value="7">7</Select.Option>*/}
                    {/*                    <Select.Option value="8">8</Select.Option>*/}
                    {/*                    <Select.Option value="9">9</Select.Option>*/}
                    {/*                    <Select.Option value="10">10</Select.Option>*/}
                    {/*                    <Select.Option value="11">11</Select.Option>*/}
                    {/*                    <Select.Option value="12">12</Select.Option>*/}
                    {/*                </Select>*/}
                    {/*            </Form.Item>*/}
                    {/*        ) : null*/}
                    {/*    }*/}
                    {/*</Form.Item>*/}

                    {/*<Form.Item*/}
                    {/*    noStyle*/}
                    {/*    shouldUpdate={(prevValues, currentValues) => prevValues.pay_by_own_card !== currentValues.pay_by_own_card}*/}
                    {/*>*/}
                    {/*    {({ getFieldValue }) =>*/}
                    {/*        getFieldValue('pay_by_own_card') === 'true' ? (*/}
                    {/*            <Form.Item label="Card expire year" name="card_expire_year" rules={[{ required: true }]} >*/}
                    {/*                <Select>*/}
                    {/*                    <Select.Option value="2022">2022</Select.Option>*/}
                    {/*                    <Select.Option value="2023">2023</Select.Option>*/}
                    {/*                    <Select.Option value="2024">2024</Select.Option>*/}
                    {/*                    <Select.Option value="2025">2025</Select.Option>*/}
                    {/*                    <Select.Option value="2026">2026</Select.Option>*/}
                    {/*                    <Select.Option value="2027">2027</Select.Option>*/}
                    {/*                    <Select.Option value="2028">2028</Select.Option>*/}
                    {/*                    <Select.Option value="2029">2029</Select.Option>*/}
                    {/*                    <Select.Option value="2030">2030</Select.Option>*/}
                    {/*                    <Select.Option value="2031">2031</Select.Option>*/}
                    {/*                    <Select.Option value="2032">2032</Select.Option>*/}
                    {/*                    <Select.Option value="2033">2033</Select.Option>*/}
                    {/*                </Select>*/}
                    {/*            </Form.Item>*/}
                    {/*        ) : null*/}
                    {/*    }*/}
                    {/*</Form.Item>*/}

                    {/*<Form.Item*/}
                    {/*    noStyle*/}
                    {/*    shouldUpdate={(prevValues, currentValues) => prevValues.pay_by_own_card !== currentValues.pay_by_own_card}*/}
                    {/*>*/}
                    {/*    {({ getFieldValue }) =>*/}
                    {/*        getFieldValue('pay_by_own_card') === 'true' ? (*/}
                    {/*            <Form.Item label="Security Code (CVV)" name="cvv" rules={[{ required: true }]} >*/}
                    {/*                <Input />*/}
                    {/*            </Form.Item>*/}
                    {/*        ) : null*/}
                    {/*    }*/}
                    {/*</Form.Item>*/}

                    {/*<Form.Item*/}
                    {/*    noStyle*/}
                    {/*    shouldUpdate={(prevValues, currentValues) => prevValues.pay_by_own_card !== currentValues.pay_by_own_card}*/}
                    {/*>*/}
                    {/*    {({ getFieldValue }) =>*/}
                    {/*        getFieldValue('pay_by_own_card') === 'true' ? (*/}
                    {/*            <Form.Item label="Card holder Name" name="card_holder_name" rules={[{ required: true }]} >*/}
                    {/*                <Input />*/}
                    {/*            </Form.Item>*/}
                    {/*        ) : null*/}
                    {/*    }*/}
                    {/*</Form.Item>*/}
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[{ required: true, message: 'Your email address', type: "email" }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Mobile"
                        name="phone"
                        rules={[{ required: true, message: 'Your phone number', pattern: '^([-]?[+][1-9][0-9]*|0)$' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Comments"
                        name="comments"
                    >
                        <Input.TextArea rows={3} />
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            xs: { span: 24, offset: 0 },
                            sm: { span: 12, offset: 6 },
                        }}>
                        <Button type="primary" htmlType="submit" block disabled={!editable}>
                            Submit
                        </Button>
                    </Form.Item>

                </Form>

            </Layout.Content>
            <Layout.Footer style={{ display: "flex", justifyContent: "center" }}>EZIWHV</Layout.Footer>
        </Layout >
    )
}

export default TAIWAN_MARTIN