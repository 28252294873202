export const COUNTRIES = [
    'MALAYSIA',
    "TAIWAN",
    "HONGKONG",
    "CHILE",
    "MEXICO",
    "PHILIPPINES",
    "SINGAPORE",
    "THAILAND",
    "VIETNAM",
    "TURKEY",
    "POLAND",
    "CHINA",
    'OTHER',
]

export const ID_TYPES = [
    {
        label: "Driver License",
        value: "DL",
    }, {
        label: 'National ID',
        value: 'NI',
    }, {
        label: 'Birth Certificate',
        value: 'BC',
    }
]